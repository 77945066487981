/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ProjectLayout from '../../components/Project';
import Layout from '../../components/Layout';
import { Seo } from '../../components/Seo';
import { ProjectPanel, VerticalWrapper } from './styled';
import { fixPlaceholderResize } from '../../utils/functions';


// TODO improve Video player loading experience
// - one option is maybe to load the Vimeo player by default if it will always be Vimeo vids
// - could also wait to transition until the player has loaded with an onload event

const ProjectTemplate = ({ data: { prismicProject: projectNode, placeHolder } }) => {
  const { data } = projectNode;
  return (
    <Layout isIndex={false}>
      <ProjectPanel>
        <Seo projectNode={projectNode} projectPath={projectNode.uid} projectSEO />
        <VerticalWrapper>
          <ProjectLayout
            videoUrl={data.video_url.url}
            thumbnail={fixPlaceholderResize(data, placeHolder)}
            title={data.title.text}
            projectType={data.project_type}
            description={data.description}
          />
        </VerticalWrapper>
      </ProjectPanel>
    </Layout>
  );
};

export default ProjectTemplate;

ProjectTemplate.propTypes = {
  data: PropTypes.shape({
    prismicProject: PropTypes.object.isRequired,
    description: PropTypes.object.isRequired,
    video_url: PropTypes.object.isRequired,
    title: PropTypes.object.isRequired,
    hero_image: PropTypes.object.isRequired,
    project_type: PropTypes.string.isRequired,
    placeHolder: PropTypes.object.isRequired
  }).isRequired
};

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        hero_image {
          localFile {
            childImageSharp {
              resize(width: 900, quality: 85) {
                src
              }
            }
          }
        }
        project_type
        title {
          text
        }
        description {
          html
        }
        video_url {
          url
        }
      }
    }
    placeHolder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        resize(width: 900, quality: 85) {
          src
        }
      }
    }
  }
`;
