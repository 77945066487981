import React from 'react';
import PropTypes from 'prop-types';
// import Img from 'gatsby-image';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';
import Back from './BackButton';
// TODO improve Video player loading experience
// - one option is maybe to load the Vimeo player by default if it will always be Vimeo vids
// - could also wait to transition until the player has loaded with an onload event

const Hero = styled.div`
  // z-index: 100;
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    position: fixed;
  }
  animation: slideUp 2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  transform: translate3d(0, 3vh, 0);
  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 3vh, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

const CreditsWrapper = styled.div`
  position: relative;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0em;
  max-width: ${props => props.theme.maxWidth};
`;

const VideoWrapper = styled.div`
display: flex;
opacity: 0;
margin: 0 auto;
min-width: calc(${props => props.theme.maxWidth} * .75);
@media (max-width: ${props => props.theme.breakpoint.m}) {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
}
position: relative;
&:before {
  display: block;
  padding-top: 56.25%;
  content: '';
}
& > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.react-player__preview {
  opacity: 1 !important;
  transition-duration: 500ms;
  transition-property: opacity;

  &:hover {
    cursor: default;
    opacity: .90 !important;
    transition-duration: 500ms;
    transition-property: opacity;
  }
}
.react-player__shadow {
  width: 100px !important;
  background: none !important;
  height: 100px !important;
  opacity: 100%;
}
/*
.react-player__play-icon {
  height: 90px;
  width: 100px;
  opacity: 100;
  z-index: 1;
  background-size: 100% 100% !important;
  border: none !important;
  opacity: 100;
  margin-left: 0px !important;
  &:hover {
    transform: scale(0.95);
    transition-duration: 300ms
    transition-property: opacity;
    opacity: 50%;
  }
*/
  animation: slideUpVideo 2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  transform: translate3d(0, 3vh, 0);
  @keyframes slideUpVideo {
    0% {
      opacity: 0;
      transform: translate3d(0, 3vh, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  animation-delay: 0.2s;
}
`;

const VideoDetails = styled.div`
  display: grid;
  grid-template-areas:
    "projecttype projecttype"
    "title credits"
  grid-template-rows: 150px 1fr;
  grid-template-columns: auto 1fr;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    padding: 0 1rem;
  }
  background: ${props => props.theme.colors.bg};
`;

const ProjecType = styled.h2`
  color: ${props => props.theme.colors.text};
  width: 200px
  text-align: left;
  padding-top: 1rem;
  text-transform: uppercase;
  opacity: 100;
  font-size: 0.8rem;
  margin-bottom: 0.1rem !important;
`;

const Title = styled.h1`
  color: ${props => props.theme.colors.text};
  max-width: ${props => props.theme.maxWidthText};
  text-align: left;
  text-transform: uppercase;
  opacity: 0;
  font-size: 1rem;
  margin-bottom: 0.75rem !important;
  animation: slideUpTitle 2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  @keyframes slideUpTitle {
    0% {
      opacity: 0;
      transform: translate3d(0, 3vh, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  animation-delay: 0.3s;
`;

const Credits = styled.main`
  padding: 0 0.5rem;
  text-transform: uppercase;
  opacity: 0;
  margin-bottom: 1rem;
  p {
    font-size: 0.8rem;
    text-align: left;
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  p,
  ul,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  blockquote {
    max-width: ${props => props.theme.maxWidthText};
    margin-left: 0;
    margin-right: auto;
  }
  li {
    margin-left: 1.45rem;
  }
  .block-img {
    max-width: 100%;
    margin-top: 6rem;
    margin-bottom: 6rem;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    margin-top: 1rem;
    margin-bottom: 1rem;
    .block-img {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }
  animation: slideUpContent 2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  @keyframes slideUpContent {
    0% {
      opacity: 0;
      transform: translate3d(0, 3vh, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  animation-delay: 0.4s;
`;

const Topper = styled.div`
  display: block;
  width: 100%;
  opacity: 0;
  height: 3.25rem;
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: appear 2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    display: none;
  }
  animation-delay: 1s;
  padding-left: 0.1rem;
`;


const ProjectLayout = ({ videoUrl, thumbnail, title, projectType, description }) => (
  <Hero>
    <Topper>
      <Back />
    </Topper>
    {videoUrl != null && (
      <VideoWrapper px={4} mx="auto">
        <ReactPlayer
          className="react-player"
          url={videoUrl}
          light={thumbnail}
          width="100%"
          height="100%"
          controls
          autoPlay
          playing
        />
      </VideoWrapper>
    )}
    <VideoDetails py={4} mx="auto">
      <ProjecType id='projecttype'>{projectType}</ProjecType>
      <div></div>
      <Title id='title'>{title}</Title>
      {description != null && (
        <Credits id='credits' dangerouslySetInnerHTML={{ __html: description.html }} />
      )}
    </VideoDetails>
  </Hero>
);

export default ProjectLayout;

ProjectLayout.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  thumbnail: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  projectType: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
};


// font code

// <script>
//   (function(d) {
//     var config = {
//       kitId: 'hlm7mcx',
//       scriptTimeout: 3000,
//       async: true
//     },
//     h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
//   })(document);
// </script>