import styled from '@emotion/styled';

export const ProjectPanel = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh !important;
  align-items: center;
  justify-content: center;
`;

export const VerticalWrapper = styled.div`
  display: flex;
  margin: auto;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    margin-top: 3.5rem;
    padding: 0;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    padding: 0;
  }
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
`;
